<script setup lang="ts">
const drawer = ref(true);
// const updateDrawer = () => {
//     drawer.value = !drawer.value
// }

// provide('drawer', {
//     drawer,
//     updateDrawer
// })
cl(`layouts.default`);
</script>

<template>
  <div>
    <v-app>
      <NavAppHeader />
      <v-main>
        <v-container>
          <!-- <Alert /> -->
          <slot />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
